import * as React from 'react';
import { useState, useEffect } from 'react';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import TopBanner from '../../common/topBanner/topBanner';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import GlobalStyles from '../../styles/globalStyles';
import CommercialLocationDefault from '../../pageSections/locations/commercialLocationDefault';
import BottomArrow from '../../components/bottomArrow/bottomArrow';
const BreadcrumbsBlockData = {
  backgroundColor: '#1fb25a',
  borderTopColor: '#1fb25a',
  title: 'Locations',
};

const LocationPageTemplate = ({ location }) => {
  const path = location?.pathname?.split('/')[1];
  const [showMapLeftPanel, setShowMapLeftPanel] = useState(true);
  return (
    <div style={{ background: '#fff' }}>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar
        activePath={location.pathname}
        activePathImageTop="#037ec4"
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        {...BreadcrumbsBlockData}
        path={location.pathname}
        isLocation={false}
        location={location}
        onClick={() => {
          setShowMapLeftPanel(!showMapLeftPanel);
        }}
      />
      <CommercialLocationDefault
      location={location}
      showMapLeftPanel={showMapLeftPanel} />
      <BottomArrow />

      <Footer showProducts={true} />
      
    </div>
  );
};

export default LocationPageTemplate;
