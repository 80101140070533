import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from '../../templates/locationTemplate/locationPageTemplateStyle';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { INLINES } from '@contentful/rich-text-types';
import { LocationTypes } from '../../utils/utils';
import IconPinBranch from '../../images/icon-pin-commercial_new.png';
import IconPdf from '../../images/icon-pdf-commercial.png';
import GoogleMapReact from 'google-map-react';
import { Row, Col } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';

let markersArray = [];
const CommercialLocationDefault = ({ location, showMapLeftPanel }) => {
  const inputTablet = useRef(null);
  useEffect(async () => {
    const hash = location?.hash?.replace('#', '');
    const waitedID = await new Promise((resolve) => {
      document?.getElementById(hash) && resolve(true);
    });
    if (waitedID) {
      setTimeout(() => {
        window.location.href = `#${hash}`;
      }, 500);
    }
  }, []);

  const optionsMainStyle = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.LeftPanelPdf
              href={asset?.node?.file?.url}
              target={'_blank'}
            >
              {children}
            </Styled.LeftPanelPdf>
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const locationDataQuery = useStaticQuery(graphql`
    query locationDataCommercial {
      allContentfulLocation(
        filter: {
          node_locale: { eq: "en-US" }
          brand: { brand: { eq: "ChevronCommercial" } }
          latitude: { ne: "null" }
          longitude: { ne: "null" }
        }
      ) {
        edges {
          node {
            address1
            address2
            node_locale
            branchHours
            branchManager
            cardlockHours
            email
            hasDef
            type {
              type
            }
            hasDiesel
            hasGasoline
            hasHeatingOil
            hasHeatingEquipment
            hasLubricants
            hasPropane
            hasLunchCounter
            hasMarkedDiesel
            hasMultiServices
            hasParking
            hasRestaurant
            hasShowers
            hasRestrooms
            hasWifi
            hasDriversLounge
            hasConvenienceStore
            latitude
            location
            longitude
            offerings {
              Services
            }
            phone
            tollfree
            pipelineCardAccepted
            postalCode
            serviceArea {
              serviceArea
            }
            testimonial {
              raw
            }
            notes {
              raw
            }
            city
            province
            id
            spaceId
            useLatLongOnly
            urlSlug
            fax
            metaDescription
          }
        }
      }
      allContentfulChevron(
        filter: { identifier: { type: { eq: "chevronCommercialLocation" } } }
      ) {
        edges {
          node {
            sections {
              ... on ContentfulContent {
                elementId
                id
                contentDetails {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);
  const bookletLink =
    locationDataQuery?.allContentfulChevron?.edges[0]?.node?.sections[0]
      ?.contentDetails;
  let phones;
  const [locationsData, setLocationsData] = useState(
    locationDataQuery?.allContentfulLocation?.edges
  );
  const [lat, setLat] = useState(parseFloat(locationsData[0]?.node?.latitude));
  const [lng, setLng] = useState(parseFloat(locationsData[0]?.node?.longitude));
  const [zoom, setZoom] = useState(5);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [clickedLocation, setClickedLocation] = useState(false);
  const [showCardLockMarkers, setShowCardLockMarkers] = useState(true);
  const [showMarinaMarkers, setShowMarinaMarkers] = useState(true);
  const [showBulkFuelMarkers, setShowBulkFuelMarkers] = useState(true);
  let InfoWindowObject = null;

  const closeOtherInfo = () => {
    if (InfoWindowObject) {
      /* detach the info-window from the marker ... undocumented in the API docs */
      InfoWindowObject?.set('marker', null);
      /* and close it */
      InfoWindowObject?.close();
      /* blank the Object */
      InfoWindowObject = null;
    }
  };
  const getMap = (loc) => {
    setLat(parseFloat(loc?.latitude));
    setLng(parseFloat(loc?.longitude));
    setClickedLocation(loc);
    if (window.innerWidth <= 767) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${loc?.location},${loc?.address1},${loc?.city},${loc?.province}, ${loc?.postalCode}`,
        '_blank'
      );
    }
  };
  const searchByCityNameOrPostalCode = (searchText) => {
    setClickedLocation({});
    let hasMultipleResults = false;
    let searchResult = locationsData.find(
      (item) =>
        item?.node?.city?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.postalCode?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.location?.trim().toLowerCase() ===
          searchText.trim().toLowerCase()
    );
    const multipleResults = locationsData?.filter(
      (item) =>
        item?.node?.city?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.postalCode?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.location?.trim().toLowerCase() ===
          searchText.trim().toLowerCase()
    );
    if (multipleResults && multipleResults.length > 1) {
      hasMultipleResults = true;
    }
    if (searchResult) {
      setLat(parseFloat(searchResult?.node?.latitude));
      setLng(parseFloat(searchResult?.node?.longitude));
      hasMultipleResults ? setZoom(10) : setZoom(15);
    } else {
      // eslint-disable-next-line no-undef
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: searchText }, function (results, status) {
        if (status === 'OK') {
          setLat(results[0]?.geometry?.location?.lat());
          setLng(results[0]?.geometry?.location?.lng());
          setZoom(10);
        }
      });
    }
  };

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    markerIcon = IconPinBranch;
    return markerIcon;
  };
  useEffect(() => {
    if (showMapLeftPanel) {
      if (location?.search) {
        setShowCardLockMarkers(
          location?.search?.includes(LocationTypes?.Cardlock.toLowerCase())
        );
        setShowMarinaMarkers(
          location?.search?.includes(LocationTypes?.Marina.toLowerCase())
        );
        setShowBulkFuelMarkers(
          location?.search?.includes(LocationTypes?.BulkFuel.toLowerCase())
        );
      }
    }
  }, []);
  useEffect(() => {
    let result = locationDataQuery?.allContentfulLocation?.edges;
    if (showMapLeftPanel) {
      if (!showCardLockMarkers) {
        result = result?.filter(
          (data) =>
            data?.node?.type[0]?.type !== LocationTypes?.Cardlock ||
            data?.node?.type?.length > 1
        );
      }
      if (!showBulkFuelMarkers) {
        result = result?.filter(
          (data) =>
            (data?.node?.type[1]?.type !== LocationTypes?.BulkFuel &&
              data?.node?.type[0]?.type !== LocationTypes?.BulkFuel) ||
            data?.node?.type?.length > 1
        );
      }
      if (!showMarinaMarkers) {
        result = result?.filter(
          (data) => data?.node?.type[0]?.type !== LocationTypes?.Marina
        );
      }

      if (!showCardLockMarkers && !showBulkFuelMarkers) {
        result = result?.filter((data) => data?.node?.type?.length === 1);
      }
    }
    setLocationsData(result);
    setLat(parseFloat(result[0]?.node?.latitude));
    setLng(parseFloat(result[0]?.node?.longitude));
  }, [showCardLockMarkers, showMarinaMarkers, showBulkFuelMarkers]);

  useEffect(() => {
    //delete all markers
    if (markersArray?.length >= 1) {
      markersArray?.forEach((marker) => {
        marker?.setMap(null);
      });
      markersArray = [];
    }

    if (maps && map && markersArray.length < 1) {
      locationsData?.map((location, index) => {
        if (location?.node?.address1) {
          const marker = new maps.Marker({
            position: new maps.LatLng(
              location?.node?.latitude ?? 0,
              location?.node?.longitude ?? 0
            ),
            icon: {
              url: getMarkerIcon(location?.node?.type), // url
              scaledSize: new google.maps.Size(33, 48), // size
            },
            map: map,
          });

          markersArray?.push(marker);
          const infoWindow = new maps.InfoWindow({
            content: `
                          <div class="infoWindow">
                              <h2>${location?.node?.location} </h2>
                              <br/>
                              <div class="infoWindow-left">
                              ${location?.node?.address1}${`,`}
                                <br />
                                ${
                                  location?.node?.address2 !== null
                                    ? location?.node?.address2 + '<br/>'
                                    : ''
                                }
                                ${location?.node?.city}, 
                                ${location?.node?.province},
                               ${
                                 location?.node?.postalCode !== null
                                   ? location?.node?.postalCode
                                   : ''
                               }
                                <div
                                  tabindex="0"
                                >
                                Phone: 
                                ${
                                  location?.node?.phone !== null
                                    ? location?.node?.phone
                                    : ''
                                }
                                <br/>
                                ${
                                  location?.node?.fax === null
                                    ? ''
                                    : `Fax:${' '}${location?.node?.fax}<br/>`
                                }
                                ${
                                  (location?.node?.fax === null &&
                                    location?.node?.branchHours === null) ||
                                  location?.node?.branchHours === null
                                    ? ''
                                    : `<br/>`
                                }
                                ${
                                  location?.node?.branchHours !== null
                                    ? `${location?.node?.branchHours}`
                                    : ''
                                }
                                <br/>
                                ${
                                  location?.node?.cardlockHours !== null
                                    ? location?.node?.cardlockHours
                                    : ''
                                }
                                  
                                </div>
                              <br/>
                              </div>
                            
                                
                          </div>`,
            ariaLabel: 'Marker',
          });
          if (
            clickedLocation &&
            clickedLocation.latitude === location.node.latitude &&
            clickedLocation.longitude === location.node.longitude
          ) {
            map.panTo(marker?.getPosition());
            closeOtherInfo();
            setZoom(14);
            InfoWindowObject = infoWindow;
            infoWindow?.open({
              anchor: marker,
              map,
            });
          }
          //marker.setMap(null);
          marker?.addListener('click', () => {
            closeOtherInfo();
            infoWindow?.open({
              anchor: marker,
              map,
            });
            map.panTo(marker?.getPosition());
            InfoWindowObject = infoWindow;
          });
        }
      });
    }
  }, [locationsData, map, maps, clickedLocation]);

  const SearchComponent = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Parkland | Chevron :: Locations</title>
        </Helmet>
        <Styled.MapSearch>
          <Styled.MapSearchSub>
            <Styled.MapSearchInput>
              <Styled.MapSearchInputField
                ref={inputTablet}
                type="text"
                placeholder="Search by City or Postal Code"
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    searchByCityNameOrPostalCode(event?.target?.value);
                  }
                }}
              />
            </Styled.MapSearchInput>
            <Styled.MapSearchSubmit>
              <Styled.MapSearchSubmitInput
                type="submit"
                value=""
                onClick={() => {
                  searchByCityNameOrPostalCode(inputTablet?.current?.value);
                }}
              />
            </Styled.MapSearchSubmit>
          </Styled.MapSearchSub>
        </Styled.MapSearch>
      </>
    );
  };

  const handleApiLoaded = (map, maps) => {
    const styles = [
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#98d0ff' }],
      },
    ];

    // eslint-disable-next-line no-undef
    const styledMap = new google.maps.StyledMapType(styles, {
      name: 'Styled Map',
    });
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };
  // const handleHours = (hours) => {
  //   const hour = hours?.split(';');
  //   return hour;
  // };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Parkland | Chevron :: Locations</title>
        <meta name="description" content></meta>
      </Helmet>
      <Styled.SpanStyle id="title"></Styled.SpanStyle>
      <Styled.LocationContainer>
        <Row className="g-0 d-none d-md-block">
          <Col style={{ position: 'relative', margin: 'auto' }} md={6}>
            <SearchComponent />
          </Col>
          <Styled.LocationDataCol>
            <Styled.GoogleMapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                  libraries: ['places', 'geometry'],
                }}
                center={{ lat, lng }}
                options={{ streetView: true, streetViewControl: true }}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setMap(map);
                  setMaps(maps);
                  handleApiLoaded(map, maps);
                }}
              />
              {showMapLeftPanel && (
                <Styled.MapLeftBarContainer>
                  <Styled.MapLeftBar>
                    <Styled.MapH2>
                      What type of Services are you looking for?
                    </Styled.MapH2>
                    <div>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Cardlock}
                          value={LocationTypes.Cardlock}
                          onChange={() =>
                            setShowCardLockMarkers(!showCardLockMarkers)
                          }
                          checked={showCardLockMarkers}
                        />
                        <span>Cardlock</span>
                      </Styled.CheckboxPin>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes?.Marina}
                          value={LocationTypes?.Marina}
                          onChange={() =>
                            setShowMarinaMarkers(!showMarinaMarkers)
                          }
                          checked={showMarinaMarkers}
                        />
                        <span>Marina</span>
                      </Styled.CheckboxPin>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes?.BulkFuel}
                          value={LocationTypes?.BulkFuel}
                          onChange={() =>
                            setShowBulkFuelMarkers(!showBulkFuelMarkers)
                          }
                          checked={showBulkFuelMarkers}
                        />
                        <span>Bulk Fuel</span>
                      </Styled.CheckboxPin>
                      <br />
                      <Styled.CheckboxPin>
                        <Styled.LeftPanelPdf>
                          <Styled.LeftPanelPdfImg
                            src={IconPdf}
                            alt="cardlock booklet"
                          />
                          <span>
                            {bookletLink &&
                              documentToReactComponents(
                                JSON.parse(bookletLink?.raw),
                                optionsMainStyle
                              )}
                          </span>
                        </Styled.LeftPanelPdf>
                      </Styled.CheckboxPin>
                    </div>
                  </Styled.MapLeftBar>
                </Styled.MapLeftBarContainer>
              )}
            </Styled.GoogleMapContainer>
          </Styled.LocationDataCol>
        </Row>
        <Row style={{ paddingTop: '20px' }} className="g-0 " id="commercial">
          <Styled.TableSubContainer xs={12} sm={12} md={12} lg>
            <Styled.LocationTable striped borderless>
              <Styled.THead>
                <tr>
                  <th style={{ width: '25%' }}>Location</th>
                  <th style={{ width: '25%' }}>Contact Info</th>
                  <th style={{ width: '25%' }}>Hours</th>
                  <th style={{ width: '12%', background: '#1fb25a' }}>Type</th>
                  <th style={{ width: '25%' }}>Offerings</th>
                </tr>
              </Styled.THead>
              <Styled.TBody>
                {locationsData
                  ?.sort((a, b) => {
                    if (a.node.city === b.node.city) {
                      return a.node.address1.localeCompare(b.node.address1);
                    } else {
                      return a.node.city.localeCompare(b.node.city);
                    }
                  })
                  .map((item, index) => {
                    // const hours = handleHours(item?.node?.cardlockHours);
                    return (
                      <tr key={index}>
                        <Styled.Td>
                          <div className="d-flex flex-column ">
                            <Styled.LinkStyle
                              href="#title"
                              onClick={() => getMap(item?.node)}
                            >
                              <Styled.AddressText>
                                <div> {item.node.location}</div>
                                <div> {item.node.address1}</div>
                                <div>
                                  {item.node.city}, {item.node.province}
                                  &nbsp;&nbsp;&nbsp;
                                  {item.node.postalCode}
                                </div>
                              </Styled.AddressText>
                            </Styled.LinkStyle>
                            {item?.node?.pipelineCardAccepted !== null &&
                              !item?.node?.pipelineCardAccepted && (
                                <Styled.PipelineStyle>
                                  {' '}
                                  Pipeline card not accepted at this site.{' '}
                                  <br />
                                </Styled.PipelineStyle>
                              )}
                            {item?.node?.notes && (
                              <Styled.PipelineStyle>
                                {documentToReactComponents(
                                  JSON.parse(item?.node?.notes?.raw)
                                )}
                              </Styled.PipelineStyle>
                            )}
                          </div>
                        </Styled.Td>
                        <Styled.Td>
                          {item.node.phone &&
                            (phones = item.node.phone.split(' ')) && (
                              <>
                                Phone:&nbsp;
                                {phones[0] !== '!' && <>{phones[0]}</>}
                                &nbsp;
                                {phones[1] && <>{phones[1]}</>}
                              </>
                            )}
                          {item.node.fax !== null && (
                            <>
                              <br />
                              Fax:&nbsp;{item?.node?.fax}
                            </>
                          )}
                          {item.node.tollfree && (
                            <>
                              <Styled.AddressParaText>
                                Toll Free:
                              </Styled.AddressParaText>
                              <Styled.LinkStyle2
                                className="popup-anchor"
                                href={`tel:${item.node.tollfree}`}
                                tabindex="0"
                              >
                                {item.node.tollfree}
                              </Styled.LinkStyle2>
                            </>
                          )}
                        </Styled.Td>
                        <Styled.Td>
                          {item?.node?.branchHours}
                          <br />
                          {item?.node?.cardlockHours}
                        </Styled.Td>
                        {showMapLeftPanel && (
                          <Styled.Td>
                            {item?.node?.type?.map((locationType, index) => {
                              return (
                                <div key={index}>{locationType?.type}</div>
                              );
                            })}
                          </Styled.Td>
                        )}
                        <Styled.Td $commercial={true}>
                          {item?.node?.offerings?.Services?.map(
                            (types, index) => {
                              return <div key={index}>{types}</div>;
                            }
                          )}
                        </Styled.Td>
                      </tr>
                    );
                  })}
              </Styled.TBody>
            </Styled.LocationTable>
          </Styled.TableSubContainer>
        </Row>
      </Styled.LocationContainer>
    </React.Fragment>
  );
};

export default CommercialLocationDefault;
